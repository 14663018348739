<template>

  <DefaultCrudView
    @create-user="createUserModal"
    @user-multi-select="userMultiSelectModal"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="tenants"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.actions="{ item }">
          
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="removeAdminModal(item)"
              >
                mdi-account-remove
              </v-icon>
            </template>
            <span>{{ $t('remove_privilege') }}</span>
          </v-tooltip>
          
        </template>
      </v-data-table>

      <DefaultModal :title="$t('Remove from admin')" ref="removeAdminModal" @submit="removeAdmin" deleteModal>
        <template v-slot:content>
          {{ $t('Are you sure you want to remove user ') }} <b>{{ selectedUser ? selectedUser.name : '' }}</b> {{ $t(' from admin list?') }}
        </template>
      </DefaultModal>

      <DefaultModal :title="$t('create_users')" ref="createUserModal" @submit="saveUsers(usersToCreate)">
        <template v-slot:content>
          <UserEmailTextarea 
            v-model="usersToCreate" 
            :rules="[v => !!v || $t('form_rules.mandatory')]"
          />
        </template>
      </DefaultModal>

      <DefaultModal :title="$t('select_users')" ref="userMultiSelectModal" @submit="saveUsers(multiSelectedUsers)">
        <template v-slot:content>
          <UserMultiSelect 
            v-model="multiSelectedUsers"
            :items="selectUsersList"
            :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
          />
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import UserMultiSelect from '@/components/core/UserMultiSelect.vue'
import UserEmailTextarea from '@/components/core/UserEmailTextarea.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import UserMutations from '@/store/core/user/mutations-types'
import UserActions from '@/store/core/user/actions-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "TenantAdmin",

  components: {
    DefaultCrudView,
    DefaultModal,
    UserMultiSelect,
    UserEmailTextarea
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapState({
      selectedUser: state => state.core.user.selectedUser,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('tenants'),
          prependIcon: this.getIconForCoreSettingsItem('tenants'),
          iconColor: 'primary',
          to: {
            name: "Tenant"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: `${this.selectedTenant ? this.selectedTenant.title : ''} - ${this.$t('admins')}`,
          disabled: true,
        }
      ]
    }
  },

  data() {
    return {
      loading: true,
      tenants: [],
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },
      itemsLength: 0,
      selectUsersList: [],
      multiSelectedUsers: [],
      usersToCreate: [],
      selectedTenant: null,
      actions: [
        {
          text: "Create Users",
          eventToEmit: "create-user"
        },
        {
          text: "Select Users",
          eventToEmit: "user-multi-select" 
        },
      ],
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
    }
  },

  mounted() {
    this.loading = true

    const tenantId = this.$route.params.tenantId

    this.[TenantActions.GET_TENANT](tenantId)
      .then((tenant) => {
        this.selectedTenant = tenant
        
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('core/tenant', [
      TenantActions.GET_TENANT,
    ]),

    ...mapActions('core/user', [
      UserActions.GET_ALL_USERS,
      UserActions.REMOVE_TENANT_ADMIN,
      UserActions.CREATE_USER_BATCH,
    ]),

    ...mapMutations('core/user', [
      UserMutations.SET_FIELD_TENANTS,
      UserMutations.SET_SELECTED_USER,
      UserMutations.SET_ROLE_FIELDS,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    reloadData() {
      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      options = {
        ...options,
        'filter[admin_tenant]' : this.selectedTenant.tenant_id
      }

      this.loading = true

      this.getUsersToSelect()
      
      this.[UserActions.GET_ALL_USERS](options)
        .then((result) => {
          this.tenants = [...result.items]

          this.itemsLength = result.total
          this.loading = false
        })
    },

    getUsersToSelect() {
      const options = { 'filter[not_admin_tenant]': this.selectedTenant.tenant_id, 'limit': 9999 }

      this.[UserActions.GET_ALL_USERS](options)
        .then((result) => {
          this.selectUsersList = [...result.items]
        })
    },

    createUserModal() {
      this.$refs.createUserModal.openModal()
    },

    userMultiSelectModal() {
      this.$refs.userMultiSelectModal.openModal()
    },

    saveUsers(userEmails) {
      const params = {
        emails: userEmails,
        tenantId: this.selectedTenant.tenant_id,
        type: 'admin',
        link: window.location.origin
      }

      this.[UserActions.CREATE_USER_BATCH](params)
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('User(s) added'))

          this.$refs.createUserModal.closeModal()
          this.$refs.userMultiSelectModal.closeModal()

          this.reloadData()
        })
    },

    removeAdminModal(user) {
      this.[UserMutations.SET_SELECTED_USER](user)
      this.$refs.removeAdminModal.openModal()
    },

    removeAdmin() {
      this.[UserActions.REMOVE_TENANT_ADMIN](this.selectedTenant.tenant_id)
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('User removed from admin'))

          this.$refs.removeAdminModal.closeModal()

          this.reloadData()
        })
    },
  }
}

</script>
